import React from "react";
import PropTypes from "prop-types";
import Box from "../Box";

const Heading = ({ as, children, ...rest }) => (
  <Box as="h1" {...rest}>
    {children}
  </Box>
);

Heading.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
};

Heading.defaultProps = {
  as: "h2",
  children: null,
};

export default Heading;
