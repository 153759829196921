import React from "react";
import { navigate } from "@reach/router";
import Box from "../components/base/Box";
import Route from "../components/base/Route";
import Section from "../components/base/Section";
import Heading from "../components/base/Heading";

const IS_BROWSER = typeof window !== "undefined" && window;

const NotFoundPage = () =>
  IS_BROWSER && (
    <Route layout="admin">
      <Section containerSx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: ["100%", null, null, "50%"],
          }}
        >
          <Heading as="h1" sx={{ fontSize: "5rem" }}>
            Oops!
          </Heading>
          <Heading sx={{ fontWeight: "semiBold", mb: 6 }}>
            We can't seem to find the page you're looking for.
          </Heading>
          <p>
            <b>Error code: 404</b>
          </p>
          <p>Here are some helpful links instead:</p>
          <Box sx={{ display: "flex", flexDirection: "column", py: 6 }}>
            <Box
              as="a"
              color="mediumGreen"
              href={navigate("/")}
              sx={{ display: "flex", justifyContent: "center" }}
              target="_blank"
            >
              Home
            </Box>
            ,
          </Box>
        </Box>
        <Box
          sx={{
            display: ["none", null, null, "inline-block"],
            width: ["100%", null, null, "50%"],
          }}
        />
      </Section>
    </Route>
  );

export default NotFoundPage;
